// <form data-controller="session">
// </form>

import ApplicationController from 'cisco-atomic-js/dist/controllers/application_controller';

export default class extends ApplicationController {
  connect() {
    this.element.submit();
  }
}
