/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'images'; /* eslint-disable-line import/no-unresolved */
import 'styles'; /* eslint-disable-line import/no-unresolved */
import '../polyfills'; /* eslint-disable-line import/no-unresolved */
import 'jquery/src/jquery';
import 'jquery-ujs/src/rails';
import translations from 'translations.json'; /* eslint-disable-line import/no-unresolved */
import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';
import LocalTime from 'local-time';
import Atomic from 'cisco-atomic-js';

const application = Application.start();
const context = require.context('controllers', true, /.js$/);
application.load(definitionsFromContext(context));
LocalTime.start();
Atomic.start(application, translations);
