import 'core-js/stable';
import 'element-closest';
import 'nodelist-foreach-polyfill';
import 'whatwg-fetch';

// https://developer.mozilla.org/en-US/docs/Web/API/ChildNode/remove#Browser_compatibility
(function (arr) { /* eslint-disable-line func-names */
  arr.forEach((item) => {
    if (item.hasOwnProperty('remove')) { /* eslint-disable-line no-prototype-builtins */
      return;
    }
    Object.defineProperty(item, 'remove', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function remove() {
        if (this.parentNode !== null) this.parentNode.removeChild(this);
      },
    });
  });
}([Element.prototype, CharacterData.prototype, DocumentType.prototype]));

(function (window, fetch) {
  window.fetchActive = 0;
  const increment = () => window.fetchActive++;
  const decrement = () => window.fetchActive--;
  return function () {
    const request = fetch(...[].slice.apply(arguments, 0));
    increment();
    request.then(decrement, decrement);
    return request;
  };
}(window, fetch));
